import revive_payload_client_4sVQNw7RlN from "/vercel/path0/apps/launch-site/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/apps/launch-site/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/apps/launch-site/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/apps/launch-site/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/apps/launch-site/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/apps/launch-site/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/apps/launch-site/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/launch-site/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/apps/launch-site/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/vercel/path0/apps/launch-site/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import motion_fmZWau4t0U from "/vercel/path0/apps/launch-site/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_client_1uFu2M4PVa from "/vercel/path0/apps/launch-site/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_PWMUhiA9tt from "/vercel/path0/apps/launch-site/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
import plugin_t2GMTTFnMT from "/vercel/path0/apps/launch-site/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import fontawesone_ZssnLKA687 from "/vercel/path0/apps/launch-site/plugins/fontawesone.js";
import hotjar_client_khHI17zbiJ from "/vercel/path0/apps/launch-site/plugins/hotjar.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  motion_fmZWau4t0U,
  plugin_client_1uFu2M4PVa,
  plugin_PWMUhiA9tt,
  plugin_t2GMTTFnMT,
  fontawesone_ZssnLKA687,
  hotjar_client_khHI17zbiJ
]