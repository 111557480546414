// ./plugins/hojar.ts
import VueHotjar from "vue-hotjar-next";
export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { hotjarId, isProduction },
  } = useRuntimeConfig();
  nuxtApp.vueApp.use(VueHotjar, {
    id: parseInt(hotjarId, 10),
    isProduction: isProduction,
    snippetVersion: 6,
  });
});
