<template>
  <div>
    <nuxt-page />
  </div>
</template>

<script setup>
useHead({
  bodyAttrs: {
    class: "h-full flex flex-col",
  },
  htmlAttrs: {
    class: "h-full",
  },
});
</script>
