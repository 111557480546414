<template>
  <main
    class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
  >
    <div class="text-center">
      <p class="text-base font-semibold text-indigo-600">
        {{ error?.statusCode }}
      </p>
      <h1
        class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
      >
        {{ title }}
      </h1>
      <p class="mt-6 text-base leading-7 text-gray-600">
        {{ message }}
      </p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <nuxt-link
          to="/"
          class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >Go back home</nuxt-link
        >
        <a
          href="mailto:support@bigleaphealth.com"
          class="text-sm font-normal text-gray-900"
          >Contact support <span aria-hidden="true">&rarr;</span></a
        >
      </div>
    </div>
  </main>
</template>

<script setup>
const props = defineProps({
  error: Object,
});
const title = ref(String);
const message = ref(String);
switch (props.error?.statusCode) {
  case 404:
    title.value = "Page not found";
    message.value = "Sorry, we couldn't find the page you're looking for.";
    break;
  case 503:
    title.value = "Service unavailable";
    message.value = "Sorry, the service is temporarily unavailable.";
    break;
  case 401:
    title.value = "Unauthorized";
    message.value = "Sorry, you are not authorized to view this page.";
    break;
  default:
    title.value = "Internal server error";
    message.value = "Sorry, something went wrong.";
    break;
}

useHead({
  bodyAttrs: {
    class: "h-full",
  },
  htmlAttrs: {
    class: "h-full",
  },
});
</script>
