import { default as PrismicPreviewnV6mpdmkqbMeta } from "/vercel/path0/apps/launch-site/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as indexuKZgUmxRESMeta } from "/vercel/path0/apps/launch-site/pages/index.vue?macro=true";
import { default as providersVWRKnmOMChMeta } from "/vercel/path0/apps/launch-site/pages/providers.vue?macro=true";
import { default as slice_45simulator9EFPgsY6taMeta } from "/vercel/path0/apps/launch-site/pages/slice-simulator.vue?macro=true";
import { default as testX9PUSZjqijMeta } from "/vercel/path0/apps/launch-site/pages/test.vue?macro=true";
export default [
  {
    name: "prismic-preview",
    path: "/preview",
    component: () => import("/vercel/path0/apps/launch-site/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/apps/launch-site/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/vercel/path0/apps/launch-site/pages/providers.vue").then(m => m.default || m)
  },
  {
    name: "slice-simulator",
    path: "/slice-simulator",
    component: () => import("/vercel/path0/apps/launch-site/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/apps/launch-site/pages/test.vue").then(m => m.default || m)
  }
]